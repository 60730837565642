<template>
  <div class="brace max-md:p-0 max-md:bg-white font-roboto">
    <div class="pt-1 pb-10 md:pb-26 md:rounded-3xl md:bg-white">
      <div class="brace mb-1 md:mb-8 pt-20 lg:pt-24 pb-8">
        <icon-facebook class="w-20 h-20 mx-auto mb-10 md:mb-15" :fontControlled="false" />

        <h2 class="mb-6 text-center text-2xl lg:text-3.5xl font-degular font-bold">
          {{ content.title }}
        </h2>

        <p class="max-w-[700px] mx-auto text-sm text-center xl:text-base">
          {{ content.body }}
        </p>
      </div>

      <div v-if="width < 860 && swiperReady">
        <Swiper @slideChange="onSlideChange" @swiper="onSwiper">
          <SwiperSlide class="p-4" v-for="(slide, index) in slides" :key="index">
            <div class="h-full p-8 pt-7 shadow-[0_0.25rem_1rem_0_rgba(0,0,0,0.25)] rounded-2xl text-center">
              <img class="!w-[80px] !h-[80px] mx-auto mb-6" :src="slide.image[0].url" />
              <p class="mb-4 text-md font-bold">{{ slide.firstName }}</p>
              <p class="mb-3 text-2xl font-bold text-green font-degular">{{ slide.slideTitle }}</p>
              <p>{{ slide.slideContent }}</p>
            </div>
          </SwiperSlide>
        </Swiper>

        <div class="p-6 flex justify-center items-center gap-2">
          <div v-for="(slide, index) in slides" :key="index" class="flex-none">
            <button
              :class="index === currentIndex ? 'bg-green border-green' : 'bg-black border-black'"
              class="rounded-full border border-solid h-2.5 w-2.5 mx-1"
              @click="onSlideClick(index)"
            ></button>
          </div>
        </div>
      </div>
      <div v-else class="flex mb-4 px-[60px]">
        <div v-for="(slide, index) in slides" :key="index" class="w-1/3 text-center">
          <img class="!w-[95px] !h-[95px] mx-auto" :src="slide.image[0].url" />

          <div class="mt-4 px-4 pt-2 border-s" :class="{ 'border-s-0': index === 0 }">
            <p class="mb-3 font-bold xl:text-lg">{{ slide.firstName }}</p>
            <p class="mb-3 font-bold text-green xl:text-2xl font-degular">{{ slide.slideTitle }}</p>
            <p>{{ slide.slideContent }}</p>
          </div>
        </div>
      </div>

      <div class="brace mt-5 md:mt-16 text-center">
        <a class="btn btn--outline"
           target="_blank"
           href="https://www.facebook.com/groups/603833893144097/"
           >{{ content.join }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import IconFacebook from '~/assets/svg/fb_rounded.svg';
import { useWindowSize } from '@vueuse/core';

export default {
  name: 'SectionFacebook',
  components: { IconFacebook },
  props: {
    content: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'local' });
    const { width } = useWindowSize();
    const currentIndex = ref(0);
    const swiper = ref(null);
    const swiperReady = ref(false);

    const slides = props.content.slides;
    const onSwiper = (sw) => {
      swiper.value = sw;
    };

    const onSlideChange = () => {
      currentIndex.value = swiper.value.realIndex;
    };

    const onSlideClick = (index) => {
      swiper.value.slideTo(index);
    };

    onMounted(() => {
      swiperReady.value = true;
    });

    return {
      t,
      width,
      slides,
      currentIndex,
      swiper,
      swiperReady,
      onSwiper,
      onSlideChange,
      onSlideClick,
    };
  }
};
</script>

<style lang="scss" scoped></style>
