<template>
  <div v-if="isOpen">
    <div role="button" @click="close" class="fixed z-[9998] top-0 left-0 bottom-0 right-0 bg-black/40"></div>

    <div
      class="fixed z-[9999] bg-white rounded-lg top-1/2 left-1/2 w-11/12 md:w-1/2 -translate-x-1/2 -translate-y-1/2 pb-15"
    >
      <div class="text-right">
        <button @click="close">
          <icon-close class="m-5"></icon-close>
        </button>
      </div>
      <div class="w-full mx-auto px-4">
        <div class="w-full pb-[60%] relative">
          <div class="absolute top-0 left-0 bottom-0 right-0">
            <iframe class="w-full h-full" :src="url" title="description" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "~/assets/svg/close.svg";

export default {
  data() {
    return {
      isOpen: true,
    };
  },
  components: {
    IconClose,
  },
  methods: {
    close() {
      this.isOpen = false;
    },
  },

  props: {
    url: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.w-226 {
  width: 226px;
}
</style>
